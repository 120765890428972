import React, { useState } from 'react';
import logo from '../../../../storage/app/public/logo.png'

const Footer = ({ footer, kategorie }) => {

    return (
        <footer className="px-4 mx-auto container mt-10">
            <div className="bg-[#171717] rounded-2xl px-4 pb-6 py-16 sm:px-6 lg:px-16 lg:py-24">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    <div>
                        <div className="flex justify-center text-[#B6C2D3] sm:justify-start">
                            <img src={logo} alt="logo" className="h-10" />
                        </div>

                        <p className="mt-6 max-w-md text-center leading-relaxed text-[#B6C2D3] sm:max-w-xs sm:text-left">
                            {footer.text}
                        </p>

                    </div>

                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-white">Naše zvieratá</p>

                            <ul className="mt-8 space-y-4 text-sm">
                                {kategorie.map((animal) => (
                                    <li key={animal.name}>
                                        <a className="text-[#B6C2D3] transition hover:text-[#B6C2D3]/75" href="/zoznam"> {animal.name} </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-white">Informácie</p>

                            <ul className="mt-8 space-y-4 text-sm">
                                {footer.links.map((link) => (
                                    <li key={link.label}>
                                        <a className="text-[#B6C2D3] transition hover:text-[#B6C2D3]/75" href={link.url}> {link.label} </a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="text-center sm:text-left">

                        </div>

                        <div className="text-center">

                            <ul className="mt-8 text-sm place-content-center md:justify-start flex p-8 gap-12">
                                <li className='flex'>
                                    <a
                                        className="flex items-center text-white hover:text-white/80 transition"
                                        href={footer.instagram} target="_blank"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-12">
                                            <path fill="currentColor"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                            </path>
                                        </svg>
                                    </a>
                                </li>

                                <li className='flex'>
                                    <a
                                        className="flex items-center text-white hover:text-white/80 transition"
                                        href={footer.facebook} target="_blank"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-12">
                                            <path fill="currentColor"
                                                d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                            </path>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
