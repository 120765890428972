import React, { useState } from 'react';
import logo from '../../../../storage/app/public/logo-dark.png'


const Navbar = ({ menu, auth }) => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <nav className="bg-white border-b border-[#EAEBF0]">
            <div className="container mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    {/* Toggle Icon */}
                    <div className="lg:hidden">
                        <button onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? (
                                <svg className="h-6 w-6" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                    <path d="M6 6l8 8M14 6l-8 8" />
                                </svg>  // Close Icon
                            ) : (
                                <svg className="h-6 w-6" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                    <path d="M3 6h14M3 10h14M3 14h14" />
                                </svg>  // Hamburger Icon
                            )}
                        </button>
                    </div>

                    {/* Logo */}
                    <div className="flex-grow lg:flex-grow-0 justify-center lg:justify-start flex">
                        <a href="/" className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900">
                            <img src={logo} alt="logo" className="h-10 mr-3" />
                        </a>
                    </div>

                    {/* Links and Actions */}
                    <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col lg:flex-row gap-4 py-4 lg:py-0 lg:gap-0 items-center absolute lg:relative w-full lg:w-auto bg-white lg:bg-transparent left-0 lg:left-auto top-28 lg:top-auto z-20`}>
                        {menu.links.map((link) => (
                            <a key={link.label} href={link.url} className="block py-2 px-4 text-sm text-[#27313F] font-medium text-base lg:ml-4 custom-link">{link.label}</a>
                        ))}
                        {auth.user ? (
                            <>
                                <a href={route('profile.edit')} className={`${isOpen ? 'flex' : 'hidden'} py-3 px-5 bg-[#BE1622] text-white rounded-[60px] hover:bg-[#BE1622]/80 lg:ml-4 transition`}>Môj profil</a>
                            </>
                        ) : (
                            <>
                                <a href="/register" className={`${isOpen ? 'flex' : 'hidden'} py-3 px-5 text-[#1A212B] rounded-[60px] hover:bg-gray-200 bg-[#ECEFF4] lg:ml-4 transition`}>Zaregistrovať sa</a>
                                <a href="/login" className={`${isOpen ? 'flex' : 'hidden'} py-3 px-5 bg-[#BE1622] text-white rounded-[60px] hover:bg-[#BE1622]/80 lg:ml-4 transition`}>Prihlásiť sa</a>
                            </>
                        )}

                    </div>

                    <div>
                        {auth.user ? (
                            <>
                                <a href={route('profile.edit')} className="hidden lg:inline-flex py-3 px-5 bg-[#BE1622] text-white rounded-[60px] hover:bg-[#BE1622]/80 lg:ml-4 transition">Môj profil</a>
                            </>
                        ) : (
                            <>
                                <a href="/register" className="hidden lg:inline-flex py-3 px-5 text-[#1A212B] rounded-[60px] hover:bg-gray-200 bg-[#ECEFF4] lg:ml-4 transition">Zaregistrovať sa</a>
                                <a href="/login" className="hidden lg:inline-flex py-3 px-5 bg-[#BE1622] text-white rounded-[60px] hover:bg-[#BE1622]/80 lg:ml-4 transition">Prihlásiť sa</a>
                            </>
                        )}

                    </div>
                </div>
            </div>


        </nav>
    );
};

export default Navbar;
